import { getApp, getApps, initializeApp} from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from "firebase/storage";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import { getPerformance } from 'firebase/performance';
import { getAuth } from 'firebase/auth';
import { getMessaging } from 'firebase/messaging';

export const firebaseConfig = {
    apiKey: import.meta.env["PUBLIC_FIREBASE_API"],
    authDomain: import.meta.env["PUBLIC_FIREBASE_AUTH_DOMAIN"],
    projectId: import.meta.env["PUBLIC_PROJECT_ID"],
    storageBucket: `${import.meta.env["PUBLIC_PROJECT_ID"]}.appspot.com`,
    messagingSenderId: import.meta.env['PUBLIC_PROJECT_FCM_VAPID_ID'],
    appId: import.meta.env["PUBLIC_PROJECT_APP_ID"],
    measurementId: import.meta.env["PUBLIC_PROJECT_MEASUREMENT_ID"]
};

const SITE_KEY = import.meta.env['PUBLIC_RECAPTCHA_SITE_KEY'];

export const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
export const analytics = getAnalytics(app);
export const perfomance = getPerformance(app);
export const db = getFirestore(app);
export const messaging = getMessaging(app);
export const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(SITE_KEY),
    isTokenAutoRefreshEnabled: true
});

export const storage = getStorage(app);

export const auth = getAuth(app);


// export async function requestPermissionForNotification() {
//     console.log("Request Permission");
//     const permission = await Notification.requestPermission();
//     return permission;
// }
